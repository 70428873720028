import cn from 'classnames';
import { BaseProps } from 'types';

export interface NewsCardBodyProps extends BaseProps {}

const NewsCardBody: React.FC<NewsCardBodyProps> = ({ className, children }) => (
  <div className={cn('news-section__body', className)}>{children}</div>
);

export default NewsCardBody;
